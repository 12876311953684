import Vue from "vue";
import Router from "vue-router";
import goTo from "vuetify/es5/services/goto";

Vue.use(Router);

const router = new Router({
  mode: "history",
  base: process.env.BASE_URL,
  // This is for the scroll top when click on any router link
  scrollBehavior: (to, from, savedPosition) => {
    let scrollTo = 0;

    if (to.hash) {
      scrollTo = to.hash;
    } else if (savedPosition) {
      scrollTo = savedPosition.y;
    }

    return goTo(scrollTo);
  },
  // This is for the scroll top when click on any router link
  routes: [
    {
      name: "User",
      path: "/Login",
      component: () => import("@/components/PtnAllService/User/Login"),
    },
    {
      name: "User",
      path: "/Register",
      component: () => import("@/components/PtnAllService/User/Register"),
    },

    {
      path: "/",
      redirect: "/HomepageTitle",
      // redirect: "/Dashboard",
      component: () => import("@/layouts/full-layout/Layout"),
      children: [
        // PTN route
        {
          name: "test",
          path: "/test",
          component: () => import("@/components/test"),
        },
        {
          name: "Homepage",
          path: "/Homepage",
          component: () => import("@/components/PtnAllService/Homepage/index"),
        },
        {
          name: "HomepageTitle",
          path: "/HomepageTitle",
          component: () =>
            import("@/components/PtnAllService/HomepageTitle/index"),
        },
        {
          name: "HomepageTitleAll",
          path: "/HomepageTitleAll",
          component: () =>
            import("@/components/PtnAllService/HomepageTitleAll/index"),
        },
        {
          name: "courseContent",
          path: "/courseContent",
          component: () =>
            import("@/components/PtnAllService/courseContent/index"),
        },
        {
          name: "courseStudy",
          path: "/courseStudy",
          component: () =>
            import("@/components/PtnAllService/courseStudy/index"),
        },
      ],
    },
    {
      path: "/authentication",
      component: () => import("@/layouts/blank-layout/Blanklayout"),
      children: [
        {
          name: "BoxedLogin",
          path: "boxedlogin",
          component: () => import("@/views/authentication/BoxedLogin"),
        },
        {
          name: "FullLogin",
          path: "fulllogin",
          component: () => import("@/views/authentication/FullLogin"),
        },
        {
          name: "BoxedRegister",
          path: "boxedregister",
          component: () => import("@/views/authentication/BoxedRegister"),
        },
        {
          name: "FullRegister",
          path: "fullregister",
          component: () => import("@/views/authentication/FullRegister"),
        },
        {
          name: "Error",
          path: "error",
          component: () => import("@/views/authentication/Error"),
        },
      ],
    },
    {
      path: "*",
      component: () => import("@/views/authentication/Error"),
    },
  ],
});

import NProgress from "nprogress";

router.beforeResolve((to, from, next) => {
  // If this isn't an initial page load.
  if (to.name) {
    // Start the route progress bar.
    NProgress.start(800);
  }
  next();
});

router.afterEach(() => {
  // Complete the animation of the route progress bar.
  NProgress.done();
});

export default router;
